import React from "react";
import Signin from "./components/Login";
import {  HashRouter as Router, Routes, Route } from "react-router-dom";


import Dashboard from "./components/dashboard";
import Security from "./components/security.js";
import Forgotpassword from "./components/forgotpassword";
import Notification from "./components/notification";
import PrivateRoute from "./util/privateRoute";
import ResetPassword from "./components/resetpassword";
import Otpvarification from "./components/otpvarification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Otpvalidation from "./components/otpvalidation";
import AdminDashboard from "./components/admindashboard";
import DashboardSidebar from "./components/dash";
import AdminProfile from "./components/adminprofile";
import Changeofpassword from "./components/changeofpassword";
import Termsandcondition from "./components/termsandconditions";
import Privacypolicy from "./components/privacypolicy";
import Settings from "./components/Settings.js";
import Settingsedit from "./components/Settingsedit";
import SuperDashboard from "./components/Superdashboard.js";
import Salary from "./components/Salary.js";
import Agentinsurance from "./components/Agentinsurance";
import EmployeeLogin from "./components/employeeLogin.js";
import LabLogin from "./components/labLogin.js";
import CompanyDetails from "./components/companyDetails.js";
import LabDetails from "./components/labDetails.js";
import EmployerLogin from "./components/employerLogin.js";
import CompanyProfile from "./components/companyProfile.js";
import EmployeesDetails from "./components/EmployeesDetails.js";
import CompanyDetailsTab from "./components/companyDetailsTab.js";
import EmpDetails from "./components/empDetails.js";
import EmployeeProfileTab from "./components/employeeProfileTab.js";
import EmployeeProfile from "./components/employeeProfile.js";
import EmployeeAppointmentDetails from "./components/employeeAppointmentDetails.js";
import AdminDetails from "./components/adminDetails.js";
import LabProfile from "./components/labProfile.js";
import Designations from "./components/Designations.js";
import TestPackages from "./components/testsPackages.js";
import Packages from "./components/packages.js";
import MappedPackagesAndDesignations from "./components/packagesAndDesignations.js";
import EmployeeAppointments from "./components/employeeAppointments.js";
import BookAnAppointment from "./components/bookAnAppointment.js";
import EmpAppointments from "./components/empAppointments.js";
import EmployeesAppointmentsDetails from "./components/employeeAppointmentDetails.js";
import EmployeeReports from "./components/employeeReport.js";
import AdminInvoices from "./components/adminInvoices.js";
import CompanyInvoices from "./components/companyInvoices.js";
import InvoicesForCompany from "./components/invoicesForCompany.js";
import FamilyDetails from "./components/Familydetails.js";
import FamilyAppointments from "./components/familyAppointments.js";
import BookAnAppointmentForFamily from "./components/familyAppointmentBooking.js";
import Payment from "./components/payment.js";
import FamilyProfileTab from "./components/familyProfileTab.js";
import TestCategories from "./components/testCategories.js";
import PaymentReference from "./components/paymentReference.js";
import LabsAnalytics from "./components/labsDashboard.js";
import AdminAnalytics from "./components/adminDashboardsWithAnalytics.js";
import DoctorLogin from "./components/doctorLogin.js";
import DoctorProfile from "./components/doctorProfile.js";
import CompanyEmployeesDetails from "./components/companyEmployeesDetails.js";
import ViewEmpProfileByDoctor from "./components/viewEmpProfileByDoctor.js";
import DocProfile from "./components/docProfile.js";
import ViewLabProfile from "./components/viewLabProfile.js";
import ViewEmpProfile from "./components/viewEmpProfile.js";

import ViewDoctorsByEmployer from "./components/viewDoctorsByEmployer.js";
import ReportsApproval from "./components/reportApproval.js";
import Financials from "./components/financials.js";
import EmployerProfileTab from "./components/employerProfileTab.js";
import BulkEnable from "./components/bulkEnable.js";
import ExternalAccountDetails from "./components/externalAccount.js";
import LabCorporateDetails from "./components/labCorporateDetails.js";
import LabCorporateLogin from "./components/labCorporateLogin.js";
import CorporateLabDetails from "./components/corporateLabDetails.js";
import LabsAndAppointmentsDetails from "./components/labDetailsAndAppointmentsTab.js";
import CorporateProfile from "./components/corporateProfile.js";
import LabCorporateProfileTab from "./components/labCorporateProfileTab.js";
import ViewCorporateLabsByAdmin from "./components/viewCorporateLabsByAdmin.js";
import InvoicesByLab from "./components/invoicesByLab.js";
import LinkedLabInvoices from "./components/linkedLabsInvoices.js";
import FinancialYearSettings from "./components/financialYearSettings.js";
import Documentation from "./components/documentation.js";
import ViewAdminProfileBySuper from "./components/viewAdminProfileBySuper.js";
import AppointmentReports from "./components/appointmentsReports.js";
import ViewAppointmentReportsByAdmin from "./components/viewAppointmentReportsByAdmin.js";
import Smtpconfig from "./components/Smtpconfigure.js";
import Smtpconfigure from "./components/Smtpconfigure.js";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/otpvalidation/:id" element={<Otpvalidation />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/employeesignin" element={<EmployeeLogin />} />
          <Route path="/labsignin" element={<LabLogin />} />
          <Route path="/employersignin" element={<EmployerLogin />} />
          <Route path="/doctorsignin" element={<DoctorLogin />} />
          <Route path="/labcorporatesignin" element={<LabCorporateLogin />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/otpverification" element={<Otpvarification />} />
         
          <Route path="termsandcondition" element={<Termsandcondition />} />
          <Route path="privacypolicy" element={<Privacypolicy />} />
         
          <Route path="/users" element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="labs-dashboard" element={<LabsAnalytics/>} />
          <Route path="admin-dashboard" element={<AdminAnalytics/>} />
            <Route path="security" element={<Security />} />
            <Route path="notification" element={<Notification />} />
            <Route path="admindashboard" element={<AdminDashboard />} />
            <Route path="dashboardsidebar" element={<DashboardSidebar />} />
            <Route path="notification" element={<Notification />} />
            <Route path="adminprofile" element={<AdminProfile />} />
            <Route path="changeofpassword" element={<Changeofpassword />} />
            <Route path="company-details" element={<CompanyDetails />} />
            <Route path="lab-details" element={<LabDetails/>} />
            <Route path="designations" element={<Designations />} />
            <Route path="test-packages" element={<TestPackages/>} />
            <Route path="test-categories" element={<TestCategories/>} />
            <Route path="packages" element={<Packages/>} />
            <Route path="package-designation-map" element={<MappedPackagesAndDesignations/>} />
            <Route path="employee-appointments" element={<EmployeeAppointments/>} />
            <Route path="book-appointment" element={<BookAnAppointment/>} />
        
            <Route path="employeesdetails" element={<EmpDetails />} />
            <Route path="employees-details" element={<EmployeesDetails />} />
            <Route path="Settings" element={<Settings />} />
            <Route path="financial-year-settings" element={<FinancialYearSettings />} />
            <Route path="documentation" element={<Documentation/>} />

            <Route path="Settingsedit/:id" element={<Settingsedit />} />
            <Route path="admin-profile/:userid" element={<ViewAdminProfileBySuper />} />


            <Route path="company-profile/:orgcode" element={<CompanyProfile />} />
            <Route path="lab-profile/:orgcode" element={<LabProfile />} />
            <Route path="emp-appointments/:userid" element={<EmpAppointments />} />


            <Route path="companydetails/:orgcode" element={<CompanyDetailsTab />} />
            <Route path="appointmentDetails" element={<EmployeeAppointmentDetails />} />
            <Route path="admin-details" element={<AdminDetails />} />
          
            <Route path="Superdashboard" element={<SuperDashboard />} />
            <Route path="employee-profile/:userid" element={<EmployeeProfileTab/>} />
            <Route path="doctor-profile/:userid" element={<DoctorProfile/>} />
            <Route path="family-profile/:memberid" element={<FamilyProfileTab/>} />
            <Route path="Salary" element={<Salary/>}/>
            <Route path="employeeprofile/:userid" element={<EmployeeProfile/>}/> 
           <Route path="Agentinsurance" element={<Agentinsurance/>}/>      
           <Route path="employees-appointments" element={<EmployeesAppointmentsDetails/>}/>  
           <Route path="employee-reports/:userid" element={<EmployeeReports/>}/>        
           <Route path="invoices" element={<InvoicesByLab/>}/> 
           <Route path="admin-invoices" element={<AdminInvoices/>}/> 
           <Route path="company-invoices" element={<CompanyInvoices/>}/> 
           <Route path="invoicesforcompany" element={<InvoicesForCompany/>}/> 
           <Route path="family-details" element={<FamilyDetails/>}/> 
           <Route path="family-appointments/:familyid" element={<FamilyAppointments/>}/> 
           <Route path="bookAnAppointmentForFamilyMember/:familyid" element={<BookAnAppointmentForFamily/>}/> 
           <Route path="razorpay/:id" element={<Payment/>} />
           <Route path="payment-reference/:id/:familyid" element={<PaymentReference/>} />
           <Route path="companyEmployeesDetails" element={<CompanyEmployeesDetails />} />
           <Route path="empProfile/:userid" element={<ViewEmpProfileByDoctor/>} />
           <Route path="view-doctor-profile" element={<DocProfile/>} />
           <Route path="view-employee-profile" element={<ViewEmpProfile/>} />
           <Route path="view-lab-profile" element={<ViewLabProfile />} />
           <Route path="view-employer-profile" element={<EmployerProfileTab />} />
           <Route path="view-doctors" element={<ViewDoctorsByEmployer />} />
           <Route path="reports-approval" element={<ReportsApproval />} />
           <Route path="financials" element={<Financials />} />
           <Route path="bulk-update/:companycode" element={<BulkEnable/>} />
           <Route path="external-account" element={<ExternalAccountDetails/>} />
           <Route path="lab-corporate-details" element={<LabCorporateDetails />} />
           {/* <Route path="lab-corporate-profile/:orgcode" element={<LabCorporateProfile/>} /> */}
           <Route path="corporate-lab-details" element={<CorporateLabDetails/>} />
           <Route path="corporate-lab-profile/:labcode" element={<LabsAndAppointmentsDetails/>}/>  
           <Route path="corporate-profile" element={<CorporateProfile/>}/>  
           <Route path="lab-corporate-profile/:orgcode" element={<LabCorporateProfileTab/>} />
           <Route path="corporate-labs/:corporateId" element={<ViewCorporateLabsByAdmin/>} />
           <Route path="view-all-invoices" element={<LinkedLabInvoices/>}/> 
           <Route path="view-reports" element={<AppointmentReports/>}/> 
           <Route path="viewReportsByAdmin" element={<ViewAppointmentReportsByAdmin/>}/> 

           <Route path="smtp-config" element={<Smtpconfigure/>}/> 


          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
