import React, { useState, useEffect } from "react";
import AdminDashboard from "../components/admindashboard";
import { Button, Form, Table, Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

function Smtpconfigure() {
  const [smtpConfig, setSmtpConfig] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    adminlink:"",
  });
  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    console.log("Smtpconfigure component loaded");
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const response = await axios.get("/api/configures", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setConfigs(response.data);
    } catch (error) {
      toast.error("Error fetching configurations");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSmtpConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      await axios.post("/api/configures/create", smtpConfig, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success("SMTP configuration created successfully!");
      fetchConfigs();
    } catch (error) {
      toast.error("Error creating SMTP configuration");
    }
  };

  return (
    <div>
      <br /><br /><br /><br />
      <AdminDashboard />
      <Container className="mt-4">
        <Row>
          <Col md={{ span: 8, offset: 2 }}> {/* Centering the card */}
            <Card>
              <Card.Body>
                <Card.Title className="text-center mb-4">
                  SMTP Configuration
                </Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="host">
                    <Form.Label>Host</Form.Label>
                    <Form.Control
                      type="text"
                      name="host"
                      value={smtpConfig.host}
                      onChange={handleChange}
                      required
                      className="w-100"
                    />
                  </Form.Group>

                  <Form.Group controlId="port">
                    <Form.Label>Port</Form.Label>
                    <Form.Control
                      type="number"
                      name="port"
                      value={smtpConfig.port}
                      onChange={handleChange}
                      required
                      className="w-100"
                    />
                  </Form.Group>

                  <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={smtpConfig.username}
                      onChange={handleChange}
                      required
                      className="w-100"
                    />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={smtpConfig.password}
                      onChange={handleChange}
                      required
                      className="w-100"
                    />
                  </Form.Group>
                  <br />
                  <Button variant="primary" type="submit" className="w-100 custom-btn">
                    Create Configuration
                  </Button>
                </Form>
              </Card.Body>
            </Card>

            <h3 className="mt-4 text-center">Existing SMTP Configurations</h3>
            <Table striped bordered hover className="mt-2">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Host</th>
                  <th>Port</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Adminlink</th>
                </tr>
              </thead>
              <tbody>
                {configs.map((config) => (
                  <tr key={config.id}>
                    <td>{config.id}</td>
                    <td>{config.host}</td>
                    <td>{config.port}</td>
                    <td>{config.username}</td>
                    <td>{config.password}</td>
                    <td>{config.adminlink}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Smtpconfigure;
