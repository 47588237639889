import React, { useState } from "react";
import { useNavigate} from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateCompanyDetails = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();

  const url = "/api/CreateCompanyandLab";
  const [companyDetails, setCompanyDetails] = useState(
    {
     username:"",
     role:"ROLE_EMPLOYER",
     organization:{
      username:"",
     companyname:"",
     companycode:"",
     contact:"",
     description:"",
     pan:"",
     gstin:"",
     tan:"",
     addressLine1:"",
     addressLine2:"",
     city:"",
     pincode:"",
     state:"",
     createddate:"",
     domainName:"",
     sendNotification:false
     }
      },
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${companyDetails.username}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (emailCheckResponse.data) {
          toast.error("Email already exist.");
          setLoading(false);
          return;
        }
      } catch (error) {
        handleError(error);
        setLoading(false);
        return;
      }
      axios
        .post(url, {
          username: companyDetails.username,
          role: "ROLE_EMPLOYER",
          organization: {
            username:companyDetails.username,
            companyname: companyDetails.companyname,
            companycode:companyDetails.companycode,
            contact:companyDetails.contact,
            description:companyDetails.description,
            pan:companyDetails.pan,
            gstin:companyDetails.gstin,
            tan:companyDetails.tan,
            addressLine1:companyDetails.addressLine1,
            addressLine2:companyDetails.addressLine2,
            city:companyDetails.city,
            pincode:companyDetails.pincode,
            state:companyDetails.state,
            createddate:companyDetails.createddate,
            domainName:companyDetails.domainName,
            sendNotification:companyDetails.sendNotification
          }
        }, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => {
          console.log(res.data.roleRequested);
          setLoading(false);
          const regiteredusername = {
            username: companyDetails.username,
            orgcode: companyDetails.companycode,
          };
          localStorage.setItem(
            "RegistrationData",
            JSON.stringify(regiteredusername)
          );
         
          onRequestClose();
          setCompanyDetails("");
          toast("Company account created successfully and Password sent to their email");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
          setCompanyDetails("");
        });
     
  }
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  function rhandle(e) {
    const { id, value } = e.target;
    setCompanyDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const handleSendNotificationChange = (e) => {
    const { checked } = e.target;
    setCompanyDetails((prevState) => ({
      ...prevState,
      sendNotification: checked
    }));
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Create Company Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="companyname">Company Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="companyname"
                      type="text"
                      placeholder="Company Name"
                      value={companyDetails.companyname}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="companycode">Company Code <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="companycode"
                      type="text"
                      placeholder="Company Code"
                      value={companyDetails.companycode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="username">Email <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="username"
                      type="email"
                      placeholder="Email"
                      value={companyDetails.username}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Contact Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="contact"
                      type="text"
                      placeholder="contact number"
                      value={companyDetails.contact}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN</label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={companyDetails.pan}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gstin">GSTIN  <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="gstin"
                      type="text"
                      placeholder="GSTIN"
                      value={companyDetails.gstin}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="tan">TAN</label>
                    <input
                      className="form-control"
                      id="tan"
                      type="text"
                      placeholder="TAN"
                      value={companyDetails.tan}
                      onChange={(e) => rhandle(e)}
                    />
                    
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="domainName">Domain Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="domainName"
                      type="text"
                      placeholder="Domain Name"
                      value={companyDetails.domainName}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>
                <br/>
                <div><h5>Company Address:</h5></div>
             
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline1">Address Line1 <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="addressLine1"
                      type="text"
                      placeholder="Address"
                      value={companyDetails.addressLine1}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline2">Address Line2</label>
                    <input
                      className="form-control"
                      id="addressLine2"
                      type="text"
                      placeholder="Address"
                      value={companyDetails.addressLine2}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="city">City <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="City"
                      value={companyDetails.city}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pincode">Pincode <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      value={companyDetails.pincode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="state">State <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      placeholder="State"
                      value={companyDetails.state}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="form-check mt-3 mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sendNotification"
                    checked={companyDetails.sendNotification}
                    onChange={handleSendNotificationChange}
                  />
                  <label className="form-check-label" htmlFor="sendNotification">
                    Send Notification
                  </label>
                  </div>
                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default CreateCompanyDetails;
